<template>
  <!-- 主要內容 -->
  <section class="member pb-8">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-8 mb-3">
          <button class="btn btn-primary" @click="ReadAll()">全部已讀</button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pb-6 pb-md-8">
          <ul class="cs-table" data-style="5">
            <li class="table__head d-none d-md-flex">
              <div class="table__head-td">訊息標題</div>
              <div class="table__head-td">通知時間</div>
            </li>
            <li
              class="table__item"
              data-toggle="modal"
              data-target="#memberMsg"
              v-for="item in PageInfo.data"
              :key="item.memberMsgId"
              @click="Getdata(item.memberMsgId)"
            >
              <div class="table__item-td table__item--type">
                <i v-if="item.status === 0" class=" text-danger pr-2">new</i
                >{{ item.memberSubject }}
              </div>
              <div class="table__item-td table__item--date">
                {{ item.createDate | toTaipei | moment('YYYY-MM-DD HH:mm:ss') }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 分頁 -->
      <Pagination
        @GetList="GetList"
        :page.sync="Pagination.pageIndex"
        :page-row="Pagination.pageSize"
        :total-count="PageInfo.count"
      />
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="memberMsg"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-3 p-md-6">
            <h5 class="modal-title mb-3" id="exampleModalCenterTitle">
              {{ GetMemberMsg.memberSubject }}
              <span class="float-right font-weight-normal"
                >{{ GetMemberMsg.createDate | toTaipei | moment('YYYY-MM-DD') }}
              </span>
            </h5>
            <p style="white-space: pre-wrap;" v-html="GetMemberMsg.memberMessage"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// 因為是vue的 所以不用 大括弧
import Pagination from '@/components/Pagination.vue';
import { GetMemberMessage, GetMemberMessageById, ReadAllMemberMessage } from '@/api/MemberApi';

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      Pagination: {
        pageIndex: 1,
        pageSize: 10,
        data: {},
      },
      PageInfo: {
        count: 0,
        data: [],
      },
      GetMemberMsg: {},
      getmsgid: 0,
    };
  },
  created() {
    // 一開始執行的地方
    // 呼叫訊息列表
    this.GetList();
  },
  methods: {
    GetList() {
      GetMemberMessage(this.Pagination).then((response) => {
        if (response.data.statusCode === 0) {
          this.PageInfo.count = response.data.count;
          this.PageInfo.data = response.data.data;

          if (this.Pagination.pageIndex !== 1 && this.PageInfo.data.length === 0) {
            this.Pagination.pageIndex = 1;
            this.GetList();
          }
        }
      });
    },
    // 讀取訊息ById
    Getdata(msgid) {
      this.getmsgid = msgid;
      GetMemberMessageById(this.getmsgid).then((response) => {
        if (response.data.statusCode === 0) {
          // 成功讀取後，直接先在前台改變狀態，不要讓new卡在上面
          const msg = this._.find(this.PageInfo.data, { memberMsgId: this.getmsgid });
          msg.status = 1;
          this.GetMemberMsg = response.data.data;
          // this.$bus.$emit('getUnReadCount');
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: response.data.message,
          });
        }
      });
    },
    // 全部已讀
    ReadAll() {
      ReadAllMemberMessage().then((response) => {
        if (response.data.statusCode === 0) {
          // 刷新列表
          if (this.Pagination.pageIndex !== 1 && this.PageInfo.data.length === 0) {
            this.Pagination.pageIndex = 1;
            this.GetList();
          }
          // this.$bus.$emit('getUnReadCount');
        }
      });
    },
  },
};
</script>
<style></style>
